import 'proxy-polyfill'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { expose } from 'comlink/dist/esm/comlink.js' // eslint-disable-line no-restricted-syntax
import { clientModuleFetcher, createClientSAC, toClientSACFactoryParamsFrom } from 'thunderbolt-site-assets-client'
import { platformFedopsMetricsReporter } from '@wix/thunderbolt-commons'
import { PlatformClientWorkerAPI } from '../core/types'
import { createCommonWorker } from '../worker-commons/clientWorker'
import { BootstrapData, ViewerAPI } from '../types'

const { initPlatformOnSite, runPlatformOnPage: runPlatformOnPageCommonWorker } = createCommonWorker()

function runPlatformOnPage(bootstrapData: BootstrapData, updateProps: ViewerAPI['updateProps'], invokeSdkHandler: ViewerAPI['invokeSdkHandler'], updateStyles: ViewerAPI['updateStyles']) {
	const siteAssetsClientWorkerAdapter = (logger: PlatformLogger) => {
		const fetchFn = self.fetch
		const {
			siteAssetsClientInitParams: {
				clientTopology,
				manifests,
				tbElementsManifests,
				siteAssetsClientConfig: { isStagingRequest },
				deviceInfo
			},
			mode: { qa },
			experiments,
			platformEnvData: {
				location: { rawUrl }
			}
		} = bootstrapData

		return createClientSAC(
			toClientSACFactoryParamsFrom({
				siteAssets: bootstrapData.siteAssetsClientInitParams,
				env: 'clientWorker',
				deviceInfo,
				qa,
				experiments,
				requestUrl: rawUrl,
				fetchFn,
				isStagingRequest,
				moduleFetcher: clientModuleFetcher(
					fetchFn,
					clientTopology,
					{
						thunderbolt: manifests,
						tbElements: tbElementsManifests
					},
					'webWorker'
				),
				siteAssetsMetricsReporter: platformFedopsMetricsReporter(logger)
			})
		)
	}

	return runPlatformOnPageCommonWorker({
		bootstrapData,
		updateProps,
		updateStyles,
		invokeSdkHandler,
		siteAssetsClientWorkerAdapter
	})
}

const platformClientWorkerAPI: PlatformClientWorkerAPI = {
	initPlatformOnSite,
	runPlatformOnPage
}
expose(platformClientWorkerAPI)
