import { BootstrapData, InitPlatformOnSiteArgs, ViewerAPI } from '../types'
import { initWorkerOnSite, runWorkerOnPage } from '../core/worker'
import { clearTimeouts } from '../client/timeoutsManager'
import _ from 'lodash'
import { Logger } from '@wix/web-bi-logger/dist/src/types' // eslint-disable-line no-restricted-syntax
import { manager as biLoggersManager } from '@wix/web-bi-logger'
import { proxy } from 'comlink/dist/esm/comlink.js' // eslint-disable-line no-restricted-syntax
import { PlatformWorkerCommonApi } from '../core/types'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client' // eslint-disable-line no-restricted-syntax

export function createCommonWorker(): PlatformWorkerCommonApi {
	const webBiLoggers: Array<Logger> = []
	// @ts-ignore
	biLoggersManager.onLoggerCreated((logger: Logger) => webBiLoggers.push(logger))

	function initPlatformOnSite({ platformEnvData, appsUrlData, componentSdksClientUrl }: InitPlatformOnSiteArgs) {
		initWorkerOnSite({
			platformEnvData,
			appsUrlData,
			componentSdksUrl: componentSdksClientUrl
		})
	}

	async function runPlatformOnPage({
		bootstrapData,
		updateProps,
		updateStyles,
		invokeSdkHandler,
		siteAssetsClientWorkerAdapter
	}: {
		bootstrapData: BootstrapData
		updateProps: ViewerAPI['updateProps']
		updateStyles: ViewerAPI['updateStyles']
		invokeSdkHandler: ViewerAPI['invokeSdkHandler']
		siteAssetsClientWorkerAdapter: (logger: PlatformLogger) => SiteAssetsClientAdapter
	}) {
		if (!bootstrapData.platformEnvData.bi.pageData.isLightbox) {
			// Wait for webBiLoggers flushing, so we don't destroy their batching by clearing timeouts.
			await Promise.all(webBiLoggers.map((logger) => logger.flush()))
			webBiLoggers.length = 0
			clearTimeouts()
		}

		const arrayOfUpdatePromises: Array<Promise<any> | void> = []
		const viewerAPI: ViewerAPI = {
			updateProps: (data: any) => {
				const promise = updateProps(data)
				arrayOfUpdatePromises.push(promise)
			},
			updateStyles: (data: any) => {
				const promise = updateStyles(data)
				arrayOfUpdatePromises.push(promise)
			},
			invokeSdkHandler: (pageId, path, ...args) => {
				if (args.length > 4) {
					console.error('sdk handlers support up to 4 arguments')
					return
				}
				const proxiedArgs = args.map((arg: any) => (_.isFunction(arg) ? proxy(arg) : arg))
				const promise = invokeSdkHandler(pageId, path, proxiedArgs[0], proxiedArgs[1], proxiedArgs[2], proxiedArgs[3])
				if (path === 'setControllerProps') {
					arrayOfUpdatePromises.push(promise)
				}
				return promise
			}
		}

		await runWorkerOnPage({
			viewerAPI,
			bootstrapData,
			siteAssetsClientWorkerAdapter
		})
		// wait for all prop updates to finish before resolving the main platform promise to make sure props are updated before render
		await Promise.all(arrayOfUpdatePromises)
	}
	return {
		initPlatformOnSite,
		runPlatformOnPage
	}
}
