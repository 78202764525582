import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace, CrmWixCodeSdkWixCodeApi } from '..'
import { AppDefIds, CREATE_CONTACT_URL, EMAIL_CONTACT_URL } from './common/config'
import { ContactInfo } from '../types'
import { validateEmailContactParams, validateContactInfo } from './common/validations'
import { post } from './common/api'
import { createFedopsLogger } from './common/fedops'

function buildEmailContact(methodName, fedopsLogger, sessionService) {
	return async function emailContact(emailId, toContact, options) {
		const { valid, processedOptions } = validateEmailContactParams(emailId, toContact, options)
		if (!valid) {
			return 'error'
		}
		const body = { emailId, contactId: toContact, options: processedOptions }
		try {
			fedopsLogger.interactionStarted(methodName)
			const resp = await post({
				url: EMAIL_CONTACT_URL,
				instanceId: sessionService.getInstance(AppDefIds.shoutOut),
				body,
			})
			fedopsLogger.interactionEnded(methodName)
			return resp
		} catch (message) {
			return message
		}
	}
}

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function CrmSdkFactory({
	platformEnvData,
	platformUtils,
}: WixCodeApiFactoryArgs<{}, {}>): { [namespace]: CrmWixCodeSdkWixCodeApi } {
	const { sessionService, biUtils } = platformUtils
	const { bi: biData } = platformEnvData

	const fedopsLogger = createFedopsLogger(biUtils, biData)
	return {
		[namespace]: {
			async createContact(contactInfo: ContactInfo): Promise<any> {
				fedopsLogger.interactionStarted('create-contact')
				if (!validateContactInfo(contactInfo)) {
					return
				}
				try {
					const { contact } = await post({
						url: CREATE_CONTACT_URL,
						instanceId: sessionService.getInstance(AppDefIds.wixCode),
						body: { contact: contactInfo },
					})
					return contact.id
				} catch (message) {
					return message
				} finally {
					fedopsLogger.interactionEnded('create-contact')
				}
			},
			emailContact: buildEmailContact('email-contact', fedopsLogger, sessionService),
			triggeredEmails: {
				emailContact: buildEmailContact('email-contact', fedopsLogger, sessionService),
				emailMember: buildEmailContact('email-member', fedopsLogger, sessionService),
			},
		},
	}
}
