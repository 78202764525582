import { PlatformEnvData, PlatformLogger } from '@wix/thunderbolt-symbols'
import { IComponentSDKLoader } from './createComponentsRegistry/platform'

export const getComponentsSDKLoader = async ({
	platformEnvData,
	loadModule,
	logger,
	componentSdksUrl,
}: {
	platformEnvData: PlatformEnvData
	loadModule: <T>(url: string, scriptName: string) => Promise<T>
	logger: PlatformLogger
	componentSdksUrl: string
}): Promise<IComponentSDKLoader> => {
	/**
	 * `platformEnvData.componentsRegistry` exists only when `specs.thunderbolt.componentsRegistry` experiment is enabled
	 */
	if (platformEnvData.componentsRegistry) {
		const { createComponentsRegistryPlatform } = await import(
			'./createComponentsRegistry/platform' /* webpackChunkName: "componentsRegistryPlatform" */
		)

		const librariesTopology = platformEnvData.componentsRegistry.librariesTopology
		const mode = platformEnvData.componentsRegistry.mode
		const componentsRegistryPlatform = createComponentsRegistryPlatform({
			runtime: platformEnvData.componentsRegistry.runtime,
			librariesTopology,
			mode,
		})

		try {
			await componentsRegistryPlatform.initialize()

			return componentsRegistryPlatform.getComponentsSDKsLoader()
		} catch (e) {
			logger.captureError(new Error('could not create platform components registry'), {
				groupErrorsBy: 'values',
				tags: { method: 'getComponentsSDKLoader' },
				extra: { librariesTopology, mode, error: e },
			})

			return {
				sdkTypeToComponentTypes: {},
				loadComponentSdks: () => {
					throw new Error('could not lot component. platform components registry was initialized with error')
				},
			}
		}
	} else {
		try {
			return loadModule<IComponentSDKLoader>(componentSdksUrl, 'componentSdks')
		} catch (e) {
			logger.captureError(new Error('could not load component SDKs loader'), {
				groupErrorsBy: 'values',
				tags: { method: 'getComponentsSDKLoader' },
				extra: { componentSdksUrl, error: e },
			})

			return {
				sdkTypeToComponentTypes: {},
				loadComponentSdks: () => {
					throw new Error('could not lot component. component SDKs loader was not loaded')
				},
			}
		}
	}
}
